import { useState } from "react"
import { Link, useHistory } from "react-router-dom"

const {
    SERVER_URL = "http://localhost:5000"
} = process.env

const Register = () => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirm, setConfirm] = useState("")
    const history = useHistory()

    async function register (e) {
        e.preventDefault()

        if (username.length === 0   ||
            email.length === 0      ||
            password.length === 0   ||
            confirm.length === 0) {
            alert("One or more empty string(s)!")
            return
        }

        if (password !== confirm) {
            alert("Passwords does not match!")
            return
        }

        const meta = {  method: 'POST',
                        headers: {'Content-type': 'application/json'},
                        body: JSON.stringify({ username: username, email: email, password: password })}

        const response = await fetch(`${SERVER_URL}/register`, meta)

        if (!response.ok) {
            response.json()
                .then(json => {
                    alert(json.message)
                })

            return
        }

        history.push("/")
    }

    return (
        <form className="register">
            <h1>quizzarex.dk</h1>
            <div>
                <input type="text" placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div>
                <input type="text" placeholder="E-Mail"
                    onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
                <input type="password" placeholder="Password"
                    autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div>
                <input type="password" placeholder="Confirm password"
                    onChange={(e) => setConfirm(e.target.value)} />
            </div>
            <div>
                <button onClick={register}>Register</button>
            </div>
            <div>
                <Link to="/"><button>Back to login</button></Link>
            </div>
        </form>
    )
}

export default Register
