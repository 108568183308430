import { useState } from "react"
import { Link } from "react-router-dom"

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const submitCredentials = (e) => {
        e.preventDefault()

        onLogin(email, password)
    }

    return (
        <form className="login">
            <h1>quizzarex.dk</h1>
            <div>
                <input type="text" placeholder="E-Mail"
                onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
                <input type="password" placeholder="Password"
                onChange={(e) => setPassword(e.target.value)} />
            </div >
            <div>
                <button onClick={submitCredentials}>Login</button>
            </div>
            <div>
                <Link to="/register"><button>Create account</button></Link>
            </div>
            <div>
                <Link to="/forgot"><button>Forgot password</button></Link>
            </div>
        </form>
    )
}

export default Login
