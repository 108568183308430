import { useRef, useState, useEffect } from "react"

const {
    SERVER_URL = "http://localhost:5000"
} = process.env

const Main = ({ onLogout, requestResource }) => {
    const isInitialMount = useRef(true);
    const [delay, setDelay] = useState(0)
    const [username, setUsername] = useState(undefined)

    const update = () => {
        requestResource(`${SERVER_URL}/`)
            .then(json => {
                if (json) {
                    const { username, now } = json
                    const delay = Date.now() - parseInt(now)

                    setDelay(delay)
                    setUsername(username)
                }
            })
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false

            update()
        }

        const interval = setInterval(update, 5000)
        return () => { clearInterval(interval) }
    })

    return (
        <div className="container">
            <h1>Hello {username}</h1>
            <p>Latency: {delay} ms</p>
            <button onClick={onLogout}>Logout</button>
        </div>
    )
}

export default Main
