import { useRef, useState, useEffect } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import Register from "./components/Register"
import Login from "./components/Login"
import Main from "./components/Main"
import Footer from "./components/Footer"

const {
    SERVER_URL = "http://localhost:5000"
} = process.env

let accessTokenTtl = undefined

const App = () => {
    const isInitialMount = useRef(true);
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [accessToken, setAccessToken] = useState(undefined)

    window.addEventListener("storage", (event) => {
        if (event.key === "logout") {
            setAccessToken("")
            setLoggedIn(false)
        } 
    })

    const login = (email, password) => {
        const options = {
            method: 'POST',
            credentials: "include",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email: email, password: password })
        }

        fetch(`${SERVER_URL}/login`, options)
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(json => {
                            const { accessToken, ttl } = json

                            accessTokenTtl = ttl
                            setAccessToken(accessToken)
                            setLoggedIn(true)
                        })
                } else if (response.status === 400) {
                    response.json()
                        .then(json => {
                            alert(json.message)
                        })
                }
            })
    }

    const refresh = () => {
        const options = {
            method: "GET",
            credentials: "include",
        }

        fetch(`${SERVER_URL}/refresh`, options)
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(json => {
                            const { accessToken, ttl } = json

                            accessTokenTtl = ttl
                            setAccessToken(accessToken)
                            setLoggedIn(true)
                        })
                } else {
                    logout()
                }
            })
    }

    const requestResource = async (url, body = undefined) => {
        let options = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            },
        }

        if (body) {
            options.method = "POST"
            options.body = body
        } else {
            options.method = "GET"
        }

        const response = await fetch(url, options);

        if (response.ok) {
            return response.json()
        }

        return undefined
    }

    const logout = async () => {
        await fetch(`${SERVER_URL}/logout`, {
            method: "POST",
            credentials: "include",
        })

        setAccessToken("")
        setLoggedIn(false)

        window.localStorage.setItem("logout", Date.now())
    }

    const silentlyUpdateAccessToken = () => {
        if (isLoggedIn) {
            if (--accessTokenTtl < 10) {
                refresh()
            }
        }
    }

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false

            refresh()
        }

        const interval = setInterval(silentlyUpdateAccessToken, 1000)
        return () => { clearInterval(interval) }
    })

    return (
        <Router>
            <div className="backslash-art"></div>
            <Route path="/" exact render={() => (
                <>
                    { 
                        isLoggedIn 
                            ? <Main accessToken={accessToken} onLogout={logout} requestResource={requestResource}/> 
                            : <Login onLogin={login}/> 
                    }
                </>
            )} />
            <Route path="/register" component={Register} />
            <Footer />
        </Router>
    )
}

export default App
